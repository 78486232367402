import React, { useState, useEffect, useRef } from "react";

import html2pdf from "html2pdf.js";
import "./my-bookings.css";
import Table from "react-bootstrap/Table";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment"; // Import Moment.js
import { Form } from "react-bootstrap";
import flightwing from "../../../assets/images/flight_wing.png";
import headerlogoimage from "../../../assets/images/Preyana-pdf.png";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const MyBookings = (props) => {
  const navigate = useNavigate();
  const [bookings, setBookings] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [upcoming, setUpcoming] = useState(true); // Default to upcoming trips
  const email = localStorage.getItem("email");

  useEffect(() => {
    getBookings(email, searchQuery, upcoming);
  }, [email, searchQuery, upcoming]); // Add searchQuery and upcoming as dependencies

  // Function to get bookings from API
  const getBookings = async (email, keyword, upcoming) => {
    const configuration = {
      method: "get",
      url: apiUrl + `/booking/bookings`, // Ensure this endpoint supports filtering
      params: {
        email: email.trim() || undefined,
        keyword: keyword.trim() || undefined,
        upcoming,
      },
    };
    try {
      const result = await axios(configuration);
      // console.log("result.data", result.data);
      setBookings(result.data); // Store the data in state
    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  };

  // Function to handle the input change
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Function to handle button clicks for trips
  const handleTrips = (isUpcoming) => {
    setUpcoming(isUpcoming);
  };
  // Create a reference to the hidden div
  const hiddenDivRef = useRef(null);

  const downloadPdf = async (bookingId) => {
    const element = hiddenDivRef.current;

    // Step 1: Fetch booking details and wait for updates
    await getBookingDetail(bookingId);
    await new Promise((resolve) => setTimeout(resolve, 0)); // Ensure React renders updates

    // Step 2: Temporarily make the element visible
    const originalDisplay = element.style.display;
    element.style.display = "block";

    // Step 3: Generate PDF from the visible element
    html2pdf()
      .from(element)
      .set({
        margin: 0.1,
        filename: "download.pdf",
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      })
      .save(`Order-${bookingId}.pdf`)
      .then(() => {
        // Step 4: Restore original display style
        element.style.display = originalDisplay;
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
        element.style.display = originalDisplay; // Ensure cleanup on failure
      });
  };

  const getSingleBooking = async (bookingId, pkId) => {
    if (bookingId) {
      // Simplified check to handle both null and empty string
      console.log("Booking ID:", bookingId);
      console.log("Primary Booking ID:", pkId);
      navigate("/singlebooking", {
        state: { order_booking_id: bookingId, pk_booking_Id: pkId },
      });
    } else {
      console.log("Invalid booking ID:", bookingId);
    }
  };

  const getBookingDetail = async (bookingId) => {
    const configuration = {
      method: "get",
      url: apiUrl + `/booking/singleorder/${bookingId}`,
      headers: { "Content-Type": "application/json" },
    };
    try {
      const result = await axios(configuration);

      if (result.data.data !== undefined) {
        setBookingData(result.data.data);
        console.log("Single order data:", result.data.data); // Store the data in state
      } else {
        alert("Please enter correct booking id");
        navigate("/mybookings"); // Redirect to a safe page or show an error message
      }
    } catch (error) {
      console.error("Error fetching booking details:", error);
    }
  };
  const formatDuration = (duration) => {
    const momentDuration = moment.duration(duration);
    const hours = momentDuration.hours();
    const minutes = momentDuration.minutes();

    return `${hours > 0 ? `${hours}h ` : ""}${minutes}m`;
  };

  return (
    <section className="innerpage-wrapper">
      <div className="container">
        <h3 className="font-weight-bold">Your Trips</h3>
        <div className="row m-3">
          <div className="col-12 col-md-6">
            <button
              type="button"
              className={`${
                upcoming === true
                  ? "trips_btn btn btn-outline-primary border rounded border-primary"
                  : "trips_btn btn btn-light text-secondary"
              }`}
              onClick={() => handleTrips(true)}
            >
              Upcoming Trips
            </button>
            <button
              type="button"
              className={`${
                upcoming === false
                  ? "trips_btn btn btn-outline-primary border rounded border-primary ml-2"
                  : "trips_btn btn btn-light text-secondary ml-2"
              }`}
              onClick={() => handleTrips(false)}
            >
              Past Trips
            </button>
          </div>
          <div className="col-12 col-md-6">
            <Form.Group className="position-relative">
              <Form.Control
                type="text"
                placeholder="Filter by keywords"
                value={searchQuery}
                onChange={handleInputChange}
                className="search-input"
              />
              <span className="search-icon">
                <i className="fa fa-search"></i>
              </span>
            </Form.Group>
          </div>
        </div>
        <div className="table-responsive" id="trips_table_container">
          <Table bordered hover>
            <thead>
              <tr>
                <th>Airlines</th>
                <th>Travel Date</th>
                <th>Depart Airport/Time</th>
                <th>Arrival Airport/Time</th>
                <th>Flight Duration</th>
                <th>Stops</th>
                <th>Itinerary</th>
              </tr>
            </thead>
            <tbody>
              {bookings.length > 0 ? (
                bookings.map((booking, index) => (
                  <React.Fragment key={booking._id}>
                    {booking.slices.map((slice, sliceIndex) => (
                      <tr
                        key={slice._id}
                        id={"flight-booking-" + booking._id}
                        className={
                          booking.slices.length > 1 && sliceIndex === 0
                            ? "disable-border"
                            : ""
                        }
                      >
                        <td>
                          <a
                            href="#"
                            onClick={(event) => {
                              event.preventDefault(); // Prevent appending `#` to the URL
                              getSingleBooking(booking.booking_id, booking._id);
                            }}
                          >
                            {booking.airlines}
                          </a>
                        </td>

                        <td>{moment(slice.travelDate).format("DD-MM-YYYY")}</td>
                        <td>
                          {" "}
                          {slice.departCityName +
                            "  " +
                            slice.departAirport +
                            " | " +
                            moment(slice.departTime).format("hh:mm A")}
                        </td>
                        <td>
                          {" "}
                          {slice.arrivalCityName +
                            "  " +
                            slice.arrivalAirport +
                            " | " +
                            moment(slice.arrivalTime).format("hh:mm A")}
                        </td>
                        <td>{slice.flightDuration}</td>
                        <td>{slice.stops == null ? 0 : slice.stops}</td>
                        {sliceIndex === 0 && (
                          <td
                            rowSpan={booking.slices.length}
                            className="download-cell align-middle text-center"
                          >
                            <button
                              type="submit"
                              className="download-link btn btn-light"
                              onClick={() => downloadPdf(booking.booking_id)}
                            >
                              <i className="fa fa-arrow-down"></i> Download
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">
                    No bookings found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {/* Hidden div with content you want to convert to PDF */}
      <div
        ref={hiddenDivRef}
        style={{ display: "none", padding: "10px" }} // Hidden div, will not be visible
        id="itinerary_download"
      >
        {bookingData && (
          <>
            <div className="header" style={{ display: "flex" }}>
              <div style={{ width: "50%", textAlign: "left" }}>
                <img src={headerlogoimage} alt="header logo" />
              </div>
              <div style={{ width: "50%", textAlign: "right", right: "10px" }}>
                <h2>Booking Reference</h2>
                <h3>{bookingData.booking_reference}</h3>
              </div>
            </div>
            <div
              className="flight-details"
              style={{ display: "block", marginBottom: "20px" }}
            >
              <div style={{ textAlign: "left" }}>
                <h2>Flight details</h2>
              </div>
              {/* Check if slices is an array and handle accordingly */}
              {Array.isArray(bookingData.slices) &&
              bookingData.slices.length > 1 ? (
                bookingData.slices.map((slice, index) => (
                  <div
                    className="flight-detail-card"
                    style={{
                      width: "100%",
                      border: "2px solid #ddd",
                      borderRadius: "8px",
                      padding: "10px",
                    }}
                  >
                    {slice.segments.map((segment, segIndex) => (
                      <>
                        <div
                          className="flight-time-detail"
                          style={{ display: "flex", margin: "5px" }}
                        >
                          <div style={{ width: "25%", textAlign: "center" }}>
                            <img
                              src={flightwing}
                              alt="flight"
                              width={50}
                              height={50}
                            />
                          </div>
                          <div style={{ width: "25%", textAlign: "center" }}>
                            <h5>
                              {" "}
                              {new Date(
                                segment.departure_datetime
                              ).toLocaleTimeString("en-US")}{" "}
                              -{" "}
                              {new Date(
                                segment.arrival_datetime
                              ).toLocaleTimeString("en-US")}
                            </h5>
                            <h6>{segment.operating_carrier.name}</h6>
                          </div>
                          <div style={{ width: "25%", textAlign: "center" }}>
                            <h5>{formatDuration(slice.duration)}</h5>
                            {segment.origin.iata_code} -{" "}
                            {segment.destination.iata_code}
                          </div>
                          <div style={{ width: "25%", textAlign: "center" }}>
                            <h5>Non-stop</h5>
                          </div>
                        </div>
                        <div
                          className="flight-origin-detail"
                          style={{ display: "flex", margin: "5px" }}
                        >
                          <div style={{ width: "40%", textAlign: "left" }}>
                            {new Date(
                              segment.departure_datetime
                            ).toLocaleString("en-US", {
                              weekday: "short",
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            })}
                          </div>
                          <div style={{ width: "60%", textAlign: "right" }}>
                            <h5>
                              Depart from {segment.origin.name} (
                              {segment.origin.iata_code}), Terminal{" "}
                              {segment.departure_terminal}
                            </h5>
                          </div>
                        </div>
                        <div
                          className="flight-destination-detail"
                          style={{ display: "flex", margin: "5px" }}
                        >
                          <div style={{ width: "50%", textAlign: "left" }}>
                            <h5>
                              Flight Duration: {formatDuration(slice.duration)}
                            </h5>
                            <h5>
                              {new Date(
                                segment.arrival_datetime
                              ).toLocaleString("en-US", {
                                weekday: "short",
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              })}
                            </h5>
                          </div>
                          <div style={{ width: "50%", textAlign: "right" }}>
                            <h5>
                              Arrive at {segment.destination.name} (
                              {segment.destination.iata_code}), Terminal{" "}
                              {segment.arrival_terminal}
                            </h5>
                          </div>
                        </div>
                        <div
                          className="flight-class-detail"
                          style={{ display: "flex" }}
                        >
                          <div style={{ width: "25%", textAlign: "center" }}>
                            <h5>
                              {segment.passengers[0].cabin_class_marketing_name}
                            </h5>
                          </div>
                          <div style={{ width: "25%", textAlign: "center" }}>
                            <h5>{segment.operating_carrier.name}</h5>
                          </div>
                          <div style={{ width: "25%", textAlign: "center" }}>
                            <h5>{segment.aircraft.name}</h5>
                          </div>
                          <div style={{ width: "25%", textAlign: "center" }}>
                            <h5>{segment.operating_carrier_flight_number}</h5>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                ))
              ) : (
                <div
                  className="flight-detail-card"
                  style={{
                    width: "100%",
                    border: "2px solid #ddd",
                    borderRadius: "8px",
                    padding: "10px",
                  }}
                >
                  {bookingData.slices?.[0] ? (
                    <>
                      {bookingData.slices?.[0].segments.map(
                        (segment, segIndex) => (
                          <>
                            <div
                              className="flight-time-detail"
                              style={{ display: "flex", margin: "5px" }}
                            >
                              <div
                                style={{ width: "25%", textAlign: "center" }}
                              >
                                <img
                                  src={flightwing}
                                  alt="flight"
                                  width={50}
                                  height={50}
                                />
                              </div>
                              <div
                                style={{ width: "25%", textAlign: "center" }}
                              >
                                <h5>
                                  {" "}
                                  {new Date(
                                    segment.departure_datetime
                                  ).toLocaleTimeString("en-US")}{" "}
                                  -{" "}
                                  {new Date(
                                    segment.arrival_datetime
                                  ).toLocaleTimeString("en-US")}
                                </h5>
                                <h6>{segment.operating_carrier.name}</h6>
                              </div>
                              <div
                                style={{ width: "25%", textAlign: "center" }}
                              >
                                <h5>
                                  {formatDuration(
                                    bookingData.slices?.[0].duration
                                  )}
                                </h5>
                                {segment.origin.iata_code} -{" "}
                                {segment.destination.iata_code}
                              </div>
                              <div
                                style={{ width: "25%", textAlign: "center" }}
                              >
                                <h5>Non-stop</h5>
                              </div>
                            </div>
                            <div
                              className="flight-origin-detail"
                              style={{ display: "flex", margin: "5px" }}
                            >
                              <div style={{ width: "40%", textAlign: "left" }}>
                                {new Date(
                                  segment.departure_datetime
                                ).toLocaleString("en-US", {
                                  weekday: "short",
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                })}
                              </div>
                              <div style={{ width: "60%", textAlign: "right" }}>
                                <h5>
                                  Depart from {segment.origin.name} (
                                  {segment.origin.iata_code}), Terminal{" "}
                                  {segment.departure_terminal}
                                </h5>
                              </div>
                            </div>
                            <div
                              className="flight-destination-detail"
                              style={{ display: "flex", margin: "5px" }}
                            >
                              <div style={{ width: "50%", textAlign: "left" }}>
                                <h5>
                                  Flight Duration:{" "}
                                  {formatDuration(
                                    bookingData.slices?.[0].duration
                                  )}
                                </h5>
                                <h5>
                                  {new Date(
                                    segment.arrival_datetime
                                  ).toLocaleString("en-US", {
                                    weekday: "short",
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                  })}
                                </h5>
                              </div>
                              <div style={{ width: "50%", textAlign: "right" }}>
                                <h5>
                                  Arrive at {segment.destination.name} (
                                  {segment.destination.iata_code}), Terminal{" "}
                                  {segment.arrival_terminal}
                                </h5>
                              </div>
                            </div>
                            <div
                              className="flight-class-detail"
                              style={{ display: "flex" }}
                            >
                              <div
                                style={{ width: "25%", textAlign: "center" }}
                              >
                                <h5>
                                  {
                                    segment.passengers[0]
                                      .cabin_class_marketing_name
                                  }
                                </h5>
                              </div>
                              <div
                                style={{ width: "25%", textAlign: "center" }}
                              >
                                <h5>{segment.operating_carrier.name}</h5>
                              </div>
                              <div
                                style={{ width: "25%", textAlign: "center" }}
                              >
                                <h5>{segment.aircraft.name}</h5>
                              </div>
                              <div
                                style={{ width: "25%", textAlign: "center" }}
                              >
                                <h5>
                                  {segment.operating_carrier_flight_number}
                                </h5>
                              </div>
                            </div>
                          </>
                        )
                      )}
                    </>
                  ) : (
                    <p>No flight details available.</p>
                  )}
                </div>
              )}
            </div>

            <div
              className="passenger-details"
              style={{ display: "block", marginBottom: "20px" }}
            >
              <div style={{ textAlign: "left" }}>
                <h2>Passengers</h2>
              </div>
            </div>
            <div
              className="passenger-detail-card"
              style={{
                width: "100%",
                border: "2px solid #ddd",
                borderRadius: "8px",
                padding: "10px",
                marginBottom: "20px",
              }}
            >
              {Array.isArray(bookingData.passengers) &&
              bookingData.passengers.length > 1 ? (
                bookingData.passengers.map((passenger, index) => (
                  <>
                    <div className="passenger-type">
                      <h3>{passenger.type}</h3>
                    </div>

                    <div
                      className="passenger-detail"
                      style={{
                        display: "flex",
                        margin: "5px",
                        marginBottom: "20px",
                      }}
                    >
                      <div style={{ width: "40%", textAlign: "left" }}>
                        <h4>Name</h4>
                        <h5>
                          {passenger.given_name} {passenger.family_name}
                        </h5>
                      </div>
                      <div style={{ width: "30%", textAlign: "left" }}>
                        <h4>Date of Birth</h4>
                        <h5>{passenger.born_on}</h5>
                      </div>
                      <div style={{ width: "30%", textAlign: "left" }}>
                        <h4>Gender</h4>
                        <h5>{passenger.gender == "f" ? "Female" : "Male"}</h5>
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <>
                  {bookingData.passengers?.[0] ? (
                    <>
                      <div className="passenger-type">
                        <h3>{bookingData.passengers?.[0].type}</h3>
                      </div>

                      <div
                        className="passenger-detail"
                        style={{
                          display: "flex",
                          margin: "5px",
                          marginBottom: "20px",
                        }}
                      >
                        <div style={{ width: "40%", textAlign: "left" }}>
                          <h4>Name</h4>
                          <h5>
                            {bookingData.passengers?.[0].given_name}{" "}
                            {bookingData.passengers?.[0].family_name}
                          </h5>
                        </div>
                        <div style={{ width: "30%", textAlign: "left" }}>
                          <h4>Date of Birth</h4>
                          <h5>{bookingData.passengers?.[0].born_on}</h5>
                        </div>
                        <div style={{ width: "30%", textAlign: "left" }}>
                          <h4>Gender</h4>
                          <h5>
                            {bookingData.passengers?.[0].gender == "f"
                              ? "Female"
                              : "Male"}
                          </h5>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>No passenger details available.</p>
                  )}
                </>
              )}
              <div className="passenger-type">
                <h3>Flight Information</h3>
              </div>

              <div
                className="flight-detail-card"
                style={{
                  width: "100%",
                  border: "2px solid #ddd",
                  borderRadius: "8px",
                  padding: "10px",
                }}
              >
                {/* Check if slices is an array and handle accordingly */}
                {Array.isArray(bookingData.slices) &&
                bookingData.slices.length > 1 ? (
                  bookingData.slices.map((slice, index) => (
                    <>
                      {slice.segments.map((segment, segIndex) => (
                        <>
                          <div
                            className="flight-class-detail"
                            style={{ display: "flex" }}
                          >
                            <div style={{ width: "25%", textAlign: "left" }}>
                              <img src={flightwing} alt="flight" />
                            </div>
                            <div style={{ width: "75%", textAlign: "left" }}>
                              <h4>
                                {" "}
                                {segment.origin.iata_code} {" to "}
                                {segment.destination.iata_code} on{" "}
                                {new Date(
                                  segment.departure_datetime
                                ).toLocaleString("en-US", {
                                  weekday: "short",
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                })}
                              </h4>
                            </div>
                          </div>
                          <div
                            className="flight-class-detail"
                            style={{ display: "flex" }}
                          >
                            {segment.passengers.map(
                              (passenger, passengerIndex) => (
                                <>
                                  <div
                                    style={{
                                      width: "25%",
                                      textAlign: "left",
                                    }}
                                  >
                                    <h4>
                                      {passenger.baggages[0].quantity}{" "}
                                      {passenger.baggages[0].type}
                                    </h4>
                                  </div>
                                  <div
                                    style={{
                                      width: "25%",
                                      textAlign: "left",
                                    }}
                                  >
                                    <h4>
                                      {passenger.baggages[1].quantity}{" "}
                                      {passenger.baggages[1].type}
                                    </h4>
                                  </div>
                                </>
                              )
                            )}
                          </div>
                        </>
                      ))}
                    </>
                  ))
                ) : (
                  <>
                    {bookingData.slices?.[0] ? (
                      <>
                        {bookingData.slices?.[0].segments.map(
                          (segment, segIndex) => (
                            <>
                              <div
                                className="flight-class-detail"
                                style={{ display: "flex" }}
                              >
                                <div
                                  style={{ width: "25%", textAlign: "left" }}
                                >
                                  <img src={flightwing} alt="flight" />
                                </div>
                                <div
                                  style={{ width: "75%", textAlign: "left" }}
                                >
                                  <h4>
                                    {segment.origin.iata_code} {" to "}
                                    {segment.destination.iata_code} on{" "}
                                    {new Date(
                                      segment.departure_datetime
                                    ).toLocaleString("en-US", {
                                      weekday: "short",
                                      day: "numeric",
                                      month: "short",
                                      year: "numeric",
                                    })}
                                  </h4>
                                </div>
                              </div>
                              <div
                                className="flight-class-detail"
                                style={{ display: "flex" }}
                              >
                                {segment.passengers.map(
                                  (passenger, passengerIndex) => (
                                    <>
                                      <div
                                        style={{
                                          width: "25%",
                                          textAlign: "left",
                                        }}
                                      >
                                        <h4>
                                          {passenger.baggages[0].quantity}{" "}
                                          {passenger.baggages[0].type + " bag"}
                                        </h4>
                                      </div>
                                      <div
                                        style={{
                                          width: "25%",
                                          textAlign: "left",
                                        }}
                                      >
                                        <h4>
                                          {passenger.baggages[1].quantity}{" "}
                                          {passenger.baggages[1].type ==
                                          "carry_on"
                                            ? "carry on bag"
                                            : ""}
                                        </h4>
                                      </div>
                                    </>
                                  )
                                )}
                              </div>
                            </>
                          )
                        )}
                      </>
                    ) : (
                      <p>No flight information details available.</p>
                    )}
                  </>
                )}
              </div>
            </div>

            <div
              className="ticket-details"
              style={{ display: "block", marginBottom: "20px" }}
            >
              <div style={{ textAlign: "left" }}>
                <h2>Ticket numbers</h2>
              </div>
            </div>
            <div
              className="ticket-detail-card"
              style={{
                width: "100%",
                border: "2px solid #ddd",
                borderRadius: "8px",
                padding: "10px",
              }}
            >
              <div className="passenger-type">
                {Array.isArray(bookingData.passengers) &&
                bookingData.passengers.length > 1 ? (
                  bookingData.passengers.map((passenger, index) => (
                    <>
                      <div
                        className="passenger-detail"
                        style={{
                          display: "flex",
                          margin: "5px",
                          marginBottom: "20px",
                        }}
                      >
                        <div style={{ width: "40%", textAlign: "left" }}>
                          <h5>
                            {passenger.given_name} {passenger.family_name} :{" "}
                            {"1"}
                          </h5>
                        </div>
                      </div>
                    </>
                  ))
                ) : (
                  <>
                    {bookingData.passengers?.[0] ? (
                      <>
                        <div
                          className="passenger-detail"
                          style={{
                            display: "flex",
                            margin: "5px",
                            marginBottom: "20px",
                          }}
                        >
                          <div style={{ width: "40%", textAlign: "left" }}>
                            <h5>
                              {bookingData.passengers?.[0].given_name}{" "}
                              {bookingData.passengers?.[0].family_name} : {"1"}
                            </h5>
                          </div>
                        </div>
                      </>
                    ) : (
                      <p>No tickets details available.</p>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default MyBookings;
