export const getFlights = () => ({
  type: "GET_FLIGHTS",
});

export const findFlights = (payload) => ({
  type: "GET_ROUTES",
  payload,
});

export const fetchFlights = (payload) => ({
  type: "GET_ROUTES",
  payload,
});

export const findContacts = (payload) => ({
  type: "GET_CONTACTS",
  payload,
});
